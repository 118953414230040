import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataConnectModule } from '@sites/data-connect';
import { UtilErrorsModule } from '@sites/util-errors';
import { UtilNavigationModule } from '@sites/util-navigation';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    DataConnectModule.forRoot({
      baseUrl: environment.apiUrl,
    }),
    UtilNavigationModule.forRoot({
      titleSuffix: 'Login',
      titleSeparator: ' | ',
    }),
    UtilErrorsModule.forRoot(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
